import DynamicIcon from 'elements/DynamicIcon.js';
import { Link } from 'react-router-dom';
import { getWebsite } from 'utils/WebsiteUtils.js';
import { MdOutlineEmail, MdOutlineLocationOn } from "react-icons/md";
import { FaRegEnvelope } from "react-icons/fa";
import { RiMapPinLine } from "react-icons/ri";

import { getTranslation } from 'utils/DictionaryUtils.js';
import globalConfig from 'config/globalConfig.js'
import LanguageSwitch from 'elements/LanguageSwitch';

import { getValueFromParameterMap } from 'cms/NBossCMS.js';

const genericItem = 'w-full py-6 first:pt-0 last:pb-0';
const titleClassName = 'font-display font-bold text-lg sm:text-sm';

export default function ContactBar(props) {
    return (
        <div className={'flex flex-col items-center divide-y divide-neutral-100 lg:text-sm ' + (props.className || '')}>

            <Language />
            <Contact />
            <Crest />
            <Jurisdiction />
            <Seat />

        </div>
    )
}

function Language(props) {
    return (
        <div className={'flex flex-col gap-3 items-center ' + genericItem}>
            <div className='text-gyeki-gray-dark '>Nyelv - Language</div>
            <LanguageSwitch />
        </div>
    );
}

function Contact(props) {
    const _data = {
        address: getValueFromParameterMap(getWebsite().globalContent, 'address'),
        phone: getValueFromParameterMap(getWebsite().globalContent, 'phone'),
        email: getValueFromParameterMap(getWebsite().globalContent, 'email'),
    }

    return (
        <div className={'flex flex-col gap-3 items-center ' + genericItem}>

            <div className={titleClassName}>{getTranslation('contact')}</div>

            <div className='flex flex-col gap-1 items-center text-center text-sm lg:text-xs'>
                <div>{_data.address}</div>
                <a className='hover:text-gyeki-gold transition-colors' href={'tel:' + _data.phone}>{_data.phone}</a>
            </div>

            <div className='flex flex-row gap-2 items-center justify-center text-lg'>
                <a className='hover:text-gyeki-gold transition-colors' href={'mailto:' + _data.email}><FaRegEnvelope /></a>
                <Link className='hover:text-gyeki-gold transition-colors' to='/kapcsolat'><RiMapPinLine /></Link>
            </div>

        </div>
    );
}

function Crest(props) {
    return (
        <div className={'flex flex-col items-center mb-1 ' + genericItem}>
            <img className='mb-2' alt='címer' src='/assets/images/cimer.png' />
            <div className='font-display font-bold text-base'>Budapest III. kerület</div>
            <div className=''>{'— ' + getTranslation('seat-number') + ' —'}</div>
            <div className='font-display font-bold'>{getTranslation('seat-title')}</div>
        </div>
    );
}

function Jurisdiction(props) {
    const _data = {
        jurisdiction: getValueFromParameterMap(getWebsite().globalContent, 'jurisdiction'),
    }

    return (
        <div className={'flex flex-col gap-3 items-center text-center ' + genericItem}>
            <div className={titleClassName}>{getTranslation('jurisdiction')}</div>
            <div className='text-sm lg:text-xs'>{_data.jurisdiction}</div>
        </div>
    );
}

function Seat(props) {
    const _data = {
        seat: getValueFromParameterMap(getWebsite().globalContent, 'seat'),
    }

    return (
        <div className={'flex flex-col gap-3 items-center ' + genericItem}>
            <div className={titleClassName}>{getTranslation('seat-code')}</div>
            <div className='text-sm lg:text-xs'>{_data.seat}</div>
        </div>
    );
}