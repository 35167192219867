

import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { getWebsite, getPageKeyByPathName, getPathNameByPageKey } from 'utils/WebsiteUtils.js';
import globalConfig from 'config/globalConfig.js'
import CmsFrontendControl from 'services/CmsFrontendControl.js';

export default function LanguageSwitch(props) {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        // console.log(location);
        // setItems(getBreadCurmbItems(location));
    }, [location])

    const onLangChange = (key) => {
        CmsFrontendControl.setLocale(key/*globalConfig.domainName, key, getPageKeyByPathName(location.pathname)*/).then(result => {
            // if (result && result.goToPage)
            //     history.push(getPathNameByPageKey(result.goToPage.pageKey) || '/');
            // else

            history.push(key == 'en-GB' ? '/about-us' : '/');
            window.location.reload();
        });
    };

    return (
        <div className='w-full flex flex-row items-center justify-center gap-2'>

            {getWebsite().availableLocales && getWebsite().availableLocales.map((item) => (<Locale key={item.key} data={item} onClick={() => onLangChange(item.key)} />))}

        </div>
    );
}

function Locale(props) {
    return (
        <button className='group' onClick={() => props.onClick()}>
            <img className='w-7 sm:w-6 opacity-75 grayscale group-hover:grayscale-0 transition' alt={props.data.name} src={'/assets/images/language/' + props.data.key + '.png'} />
        </button>
    );
};